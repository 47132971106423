<!-- 文件描述: 采集看板列表 -->
<template>
  <div class="acquisition-board-list">
    <div class="active">
      <div class="active-info">
        <div class="item-info">
          <p class="lable">任务名称：</p>
          <el-input v-model="taskName" clearable placeholder="请输入任务名称" @change="searchData"><i slot="prefix" class="el-input__icon el-icon-search" @click="searchData"></i></el-input>
        </div>
      </div>
    </div>
    <div class="content">
      <el-tabs v-model="activeName" @tab-click="handleClick" :page="false">
        <el-tab-pane label="python" name="python"></el-tab-pane>
        <el-tab-pane label="java" name="java"></el-tab-pane>
        <el-tab-pane label="python代理" name="python代理"></el-tab-pane>
        <el-tab-pane label="java代理" name="java代理"></el-tab-pane>
      </el-tabs>
      <el-table :data="tableData" stripe border :header-cell-class-name="handleHeadAddClass" @sort-change="sortTaskName" >
        <el-table-column prop="taskName" label="任务名称" min-width="100" align="center" :show-overflow-tooltip="true" sortable="custom">
          <template slot-scope="scope">
            <div class="goDetails" @click="goDetails(scope.row.taskName)">
              {{ scope.row.taskName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="previousStartTime" label="上轮开始时间" min-width="120" :show-overflow-tooltip="true" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="previousEndTime" label="上轮结束时间" min-width="120" :show-overflow-tooltip="true" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="startTime" label="本次开始时间" min-width="120" :show-overflow-tooltip="true" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="endTime" label="本次结束时间" min-width="120" :show-overflow-tooltip="true" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="status" label="状态" min-width="120" :show-overflow-tooltip="true" align="center">
          <template slot-scope="scope">
            <div class="statusDiv" v-if="scope.row.status==0">
              <div class="statusDivMain">尚未运行</div>
              <div class="statusDivClick" @click="setStatusQuery(scope.row.taskName,3)">开始</div>
            </div>
            <div class="statusDiv" v-if="scope.row.status==1">
              <div class="statusDivMain">运行中</div>
              <div class="statusDivClick" @click="setStatusQuery(scope.row.taskName,4)">暂停</div>
            </div>
            <div class="statusDiv" v-if="scope.row.status==2">
              <div class="statusDivMain">已完成</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="allCount" label="url数量" width="180" align="center">
          <template slot-scope="scope">
            {{ scope.row.parseCount + " / " + scope.row.allCount }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="380">
          <template slot-scope="scope">
            <div class="tableBtn">
              <div class="tableBtnMain" @click="setStatusQuery(scope.row.taskName,2)">重置</div>
              <div class="tableBtnMain" @click="setStatusQuery(scope.row.taskName,1)">重新运行</div>
              <div class="tableBtnMain" @click="setStatusQuery(scope.row.taskName,5)">运行下级地址</div>
              <div class="tableBtnMain" @click="setStatusQuery(scope.row.taskName,6)">抽取下级地址</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="changeSize"
          @current-change="changeCurrent"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getBoardList, setStatus } from "@/http/api/acquisitionBoard.js"
export default {
  name: "acquisitionBoardList",
  data() {
    return {
      taskName: "",//任务名称
      isPy: 1,//是否为python运行程序 0为不是 1为是
      isProxy: 0,//是否需要代理 0不需要 1需要
      activeName: "python",//tab选中
      tableData: [],//列表数据
      currentPage: 1,//当前页
      pageSize: 10,//每页显示条数
      total: 0,//总条数
      orderBy: [],//排序，内容为字符串数组，如["taskName desc","startTime asc"]
    };
  },
  mounted() {
    //获取列表数据
    this.getData();
  },
  methods: {
    //获取列表数据
    async getData() {
      let { body } = await getBoardList({ pages: this.currentPage, rows: this.pageSize, taskName: this.taskName, isPy: this.isPy, isProxy: this.isProxy, orderBy: this.orderBy.join(",") });
      if(body){
        this.tableData = body.content;
        this.total = body.total;
      }else{
        this.tableData = [];
        this.total = 0;
      }
    },
    //排序
    sortTaskName(column, prop, order){
      //不管orderBy中包不包含当前点击的排序，并且不管当前点击的排序是升降还是清空，都把orderBy中当前点击的列的排序删除，然后再添加当前点击的排序
      this.orderBy = this.orderBy.filter(item => item.indexOf(column.prop) == -1);
      //因为已经删除了orderBy中包不包含当前点击的排序，所以当前点击的排序如果是清空的话就不再往orderBy中添加
      //判断是什么排序，然后添加到orderBy中
      if(column.order == "ascending"){
        this.orderBy.push(column.prop + "__asc");
      }else if(column.order == "descending"){
        this.orderBy.push(column.prop + "__desc");
      };
      //把this.orderBy按照taskName,startTime,endTime,previousStartTime,previousEndTime排序
      this.orderBy = this.orderBy.sort((a,b) => {
        if(a.indexOf("taskName") != -1){
          return -1;
        }else if(b.indexOf("taskName") != -1){
          return 1;
        }else if(a.indexOf("startTime") != -1){
          return -1;
        }else if(b.indexOf("startTime") != -1){
          return 1;
        }else if(a.indexOf("endTime") != -1){
          return -1;
        }else if(b.indexOf("endTime") != -1){
          return 1;
        }else if(a.indexOf("previousStartTime") != -1){
          return -1;
        }else if(b.indexOf("previousStartTime") != -1){
          return 1;
        }else if(a.indexOf("previousEndTime") != -1){
          return -1;
        }else if(b.indexOf("previousEndTime") != -1){
          return 1;
        }
      });
      //获取列表数据
      this.getData();
    },
    //修正表头排序图标的样式
    handleHeadAddClass({ column }){
      let order = this.orderBy.filter(item => item.indexOf(column.property) != -1);
      if(order.length > 0){
        if(order[0].indexOf("asc") != -1){
          column.order = "ascending";
        }else if(order[0].indexOf("desc") != -1){
          column.order = "descending";
        }
      }
    },
    //根据任务名称搜索数据
    searchData() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.getData();
    },
    //tab切换
    handleClick(params) {
      if (params.name == "python") {
        this.isPy = 1;
        this.isProxy = 0;
      } else if (params.name == "java") {
        this.isPy = 0;
        this.isProxy = 0;
      } else if (params.name == "python代理") {
        this.isPy = 1;
        this.isProxy = 1;
      } else if (params.name == "java代理") {
        this.isPy = 0;
        this.isProxy = 1;
      };
      this.currentPage = 1;
      this.pageSize = 10;
      this.getData();
    },
    //当前页
    changeCurrent(val) {
      this.currentPage = val;
      this.getData();
    },
    //每页显示条数
    changeSize(val) {
      this.pageSize = val;
      this.getData();
    },
    //修改状态
    async setStatusQuery(taskName,taskOperate){
      let { code, msg } = await setStatus({ taskName: taskName, taskOperate: taskOperate });
      if(code==200){
        this.$alert('该操作需60秒生效，请勿多次操作!', msg, {
          confirmButtonText: '我已知晓',
        });
        this.getData();
      }else{
        this.$message({
          message: msg,
          type: 'error'
        });
      }
    },
    //跳转详情页面传入名称
    goDetails(taskName){
      this.$router.push({ name: "acquisitionBoardDetails", params: { taskName: taskName, activeName: this.activeName } });
    }
  },
};
</script>

<style lang="scss" scoped>
.acquisition-board-list {
  padding: 30px 24px;
}
.active {
  display: flex;
}
.active-info {
  display: flex;
  align-items: center;
  .item-info {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .lable {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      white-space: nowrap;
    }
  }
}
.statusDiv{
  display: flex;
  justify-content: center;
  .statusDivClick{
    margin-left: 10px;
    color: #507CE5;
    cursor: pointer;
  }
}
.tableBtn{
  color: #507CE5;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  display: flex;
  justify-content: center;
  .tableBtnMain{
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #D8DCE6;
    border-left: none;
    &:first-child{
      border-radius: 5px 0 0 5px;
      border-left: 1px solid #D8DCE6;
    }
    &:last-child{
      border-radius: 0 5px 5px 0;
    }
  }
}
.goDetails{
  color: #507CE5;
  cursor: pointer;
  text-decoration: underline;
}
//分页样式
.pagination{
  margin-top: 20px;
  text-align: right;
}
//表格样式
::v-deep .el-table th.el-table__cell {
  background: #EBF0FF;
  border-right: 1px solid #dfdbdb;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #F7FAFF;
}
::v-deep .el-table td.el-table__cell {
  // border: none;
}
</style>